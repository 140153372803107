import {
    Eligibility271ResponseSerializer,
    EligibilityRequestDataSerializer,
    EligibilityRequestDetailSerializer,
    EligibilityRequestSerializer,
    EligibilitySavedDiffSerializer,
    EligibilitySentDiffSerializer,
    PayerAltNameDetailSerializer,
    UserSimpleSerializer,
} from '../@core/api.service';
import {ELIGIBILITY_REQUEST_ALERTS} from '../@core/eligibility_request_alerts';
import {ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES} from '../@core/eligibility_request_response_status_choices';

export type InsuranceEligibilityStatus = keyof typeof ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES;
export type InsuranceEligbilityAlert = keyof typeof ELIGIBILITY_REQUEST_ALERTS;

export class InsuranceEligibility implements EligibilityRequestDetailSerializer {
    id: number;
    patient: number;
    payer_name: PayerAltNameDetailSerializer;
    reject_reason: string;
    request_data: EligibilityRequestDataSerializer;
    response_data: any;
    response_data_271?: Eligibility271ResponseSerializer;
    response_data_html: string;
    response_status: InsuranceEligibilityStatus;
    timestamp: Date | string;
    transaction_id: string;
    user: UserSimpleSerializer;
    saved_diff?: EligibilitySavedDiffSerializer;
    sent_diff?: EligibilitySentDiffSerializer;
    saved_alerts?: InsuranceEligbilityAlert[];
    sent_alerts?: InsuranceEligbilityAlert[];
    in_sync?: boolean;

    constructor(data: EligibilityRequestDetailSerializer | EligibilityRequestSerializer) {
        if (!data) return;
        Object.assign(this, data);
    }

    get statusDef() {
        return InsuranceEligibility.getStatusDef(this.response_status);
    }

    static getStatusDef(status: InsuranceEligibilityStatus) {
        if (!status) return null;

        const label = ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES[status] || status;
        switch (status) {
            case 'ACTIVE_COVERAGE':
                return {
                    color: 'success',
                    icon: 'check-circle',
                    label,
                };
            case 'INACTIVE_COVERAGE':
                return {
                    color: 'danger',
                    icon: 'times-circle',
                    label,
                };
            case 'ERROR':
            case 'GENERATE_ERROR':
            case 'CANNOT_PROCESS':
            case 'CLEARINGHOUSE_ERROR':
            case 'PARSE_ERROR':
                return {
                    color: 'danger',
                    icon: 'exclamation-triangle',
                    label,
                };
            case 'SUBSCRIBER_NOT_FOUND':
            case 'INVALID_OR_MISSING_DATA':
                return {
                    color: 'danger',
                    icon: 'search',
                    label,
                };
            case 'UNKNOWN':
            default:
                return {
                    color: 'secondary',
                    icon: 'question',
                    label,
                };
        }
    }

    static getAlertText(alert: InsuranceEligbilityAlert) {
        return ELIGIBILITY_REQUEST_ALERTS[alert] || alert;
    }
}
